const tdModule = () => import(/* webpackChunkName: 'components/topDestinations' */'../../components/topDestinations/topDestinations');
const videoModule = () => import(/* webpackChunkName: 'components/video' */'../../components/video/video');
const seeMoreModule = () => import(/* webpackChunkName: 'components/seeMore' */'../../components/seeMore/seeMore');
const uspInformationModule = () => import(/* webpackChunkName: 'components/uspInformation' */'../../components/uspInformation/uspInformation');
const imgGridModule = () => import(/* webpackChunkName: 'components/imgGrid' */'../../components/imgGrid/imgGrid');
const littleGenericEncartModule = () => import(/* webpackChunkName: 'components/littleGenericEncart' */'../../components/littleGenericEncart/littleGenericEncart');
const newsModule = () => import(/* webpackChunkName: 'components/news' */'../../components/news/news');
const homeMediaModule = () => import(/* webpackChunkName: 'components/homeMedia' */'../../components/homeMedia/homeMedia');
const housingsModule = () => import(/* webpackChunkName: 'components/homeMedia' */'../../components/housings/housings');

$(() => {
  videoModule().then(({video}) => {
    new video();
  });

  tdModule().then(({td}) => {
    td.initOrRefresh();
  });

  seeMoreModule().then(({seeMore}) => {
    seeMore.init();
  });

  uspInformationModule().then(({uspInformation}) => {
    uspInformation.init();
  });

  imgGridModule().then(({imgGrid}) => {
    imgGrid.init();
  });

  littleGenericEncartModule().then(({littleGenericEncart}) => {
    littleGenericEncart.init();
  });

  newsModule().then(({news}) => {
    news.init();
  });

  homeMediaModule().then(({homeMedia}) => {
    homeMedia.init();
  });
  housingsModule().then(({housings}) => {
    housings.init();
  });
});